<template>
  <div style="padding: 15px;" v-show="this.allow">
    <div v-text="test"></div>

    <div>
      <el-row>
        <el-col :span="24">
          <el-input placeholder="搜索收款人" @change="getImpress" v-model="searchData.keyword">
            <template v-slot:append>
              <el-button>搜索</el-button>
            </template>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-card :style="item.id === selectId ? 'border:1px solid red;margin-top:6px;' : 'margin-top:6px;'" v-for="(item, key) in options" :key="key" @click="selectData(item)">
        <el-row>
          <el-col :span="24">
            <div><span v-text="item.person.name"></span> <el-tag v-text="item.person.remark" type="info"></el-tag></div>
            <div>账号：<span v-text="item.person.account"></span></div>
            <div><span>未核销金额</span>：<span v-text="item.balance"></span></div>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <div v-show="options.length <= 0">
      <div style="text-align: center;">暂无数据</div>
    </div>

  </div>
</template>

<script>
import {apiGetAgentJssdk, apiGetJssdk} from '@/api/commonApi'
import {apiGetImpress} from '@/api/shareWeworkProjectApi'
import * as ww from "@wecom/jssdk"

export default {
  created() {
    if (this.$route.query.token) {
      this.allow = true
      this.key = this.$route.query.key
      this.init()
      this.getImpress()
    }
  },
  data() {
    return {
      test: '',
      key: '',
      selectId: 0,
      allow: false,
      options: [],
      searchData: {
        company_id: 0,
        per_page: 500,
        page: 1,
        keyword: ''
      },
      jsapi: ['saveApprovalSelectedItems', 'getApprovalSelectedItems'],
    }
  },
  methods: {
    getImpress() {
      this.searchData.company_id = this.$route.query.company_id
      apiGetImpress(this.searchData).then(rsp => {
        this.options = rsp
      })
    },
    init() {
      this.ua = navigator.userAgent.toLowerCase()
      if (/wxwork/i.test(this.ua)) {
        ww.register({
          corpId: 'ww07f39146d675193a',
          agentId: '1000003',
          jsApiList: this.jsapi,
          getConfigSignature: async (url) => {
            return await apiGetJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          getAgentConfigSignature: async (url) => {
            return await apiGetAgentJssdk({
              url: url,
              apiList: this.jsapi,
            })
          },
          onConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
          onAgentConfigFail: (data) => {
            this.test = data
            // this.text = '还未支持此登陆方式(01)'
          },
        })
      }
    },
    selectData (value) {
      ww.saveApprovalSelectedItems({
        key: this.key,
        selectedData: [{
          key: value.id.toString(),
          value: value.person.name + ' 「' + value.person.account + '」 ',
        }],
        success: () => {
          this.selectId = value.id
        },
        fail: (res) => {
          this.test = res
        }
      })
    }
  }

}
</script>
